import types from "../types/pipelineListingTypes";

export const triggerPopup = (data) => (dispatch) => {
    dispatch({
        type: types.POPUP_TRIGGER,
        data 
    })
}

export const resetPopup = () => (dispatch) => {
    dispatch({
        type: types.POPUP_RESET
    })
}
