import React from "react";
import "./HandwritingDetection.scss";
import { useSelector } from "react-redux";

const HandwritingDetection = (props) => {
	const outputData = useSelector((state) => state.nebulaCategories?.prediction);
	const image = useSelector((state) => state.nebulaCategories.document);

	const selectedService = useSelector(
		(state) => state.nebulaCategories?.service
	);
	const isGCP = selectedService.cloudServiceProvider === "gcp";
	const getText = () => {
		if (isGCP) {
			return outputData["OCR_Data"]?.join(" ");
		}
		return ""
	};

	return (
		<div className='handwriting-detection-wrapper'>
			<div className="image-container">
				<img src={image && URL.createObjectURL(image)} alt="uploadedImage"/>
			</div>
			<div className="text-container">{getText()}</div>
		</div>
	);
};
export default HandwritingDetection;
