import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Tab from "../tabs/Tab";
import Tabs from "../tabs/Tabs";
import PaiProgressBar from "./../pie-progress-bar/PaiProgressBar";
import "./SpeechToText.scss";

const SpeechToText = (props) => {
  const [speechToTextRes, setSpeechToTextRes] = useState({});
  const outputData = useSelector((state) => state.nebulaCategories?.prediction);

  const selectedService = useSelector(
    (state) => state.nebulaCategories.service
  );
  const isGCP = selectedService.cloudServiceProvider === "gcp";

  const getText = () => {
    if (isGCP) {
      const data = outputData["response is"]?.results?.[0] || [];
      const highestConfidence = data?.alternatives?.reduce(
        (acc, cur) => !acc || (acc.confidence < cur.confidence ? cur : acc)
      );
      setSpeechToTextRes(highestConfidence);
    }
  };

  const getInsights = (item) => {
    return Object.keys(item).map((i) => (
      <>
        <div className="key-value-pair">
          <span className="key">{i.replaceAll("_", " ")}:</span>
          {Array.isArray(item[i]) ? (
            item[i].map((entity,index) => <span className="value">{`${entity}${index+1!==item[i].length? ",":""}`}</span>)
          ) : (
            <span className="value">{item[i]}</span>
          )}
        </div>
      </>
    ));
  };

  useEffect(() => {
    getText();
  }, [outputData]);

  if (selectedService.title.toLowerCase() === "speech_to_text") {
    return (
      <div className="speech-to-text-wrapper">
        {Object.entries(speechToTextRes).length && (
          <>
            <div className="text-section">{speechToTextRes.transcript}</div>
            <div className="accuracy-section">
              <h2>Confidence</h2>
              <PaiProgressBar
                size={35}
                strokeWidth={3}
                percentage={speechToTextRes.confidence * 100}
                color="#39AB5F"
              />
            </div>
          </>
        )}
      </div>
    );
  } else {
    return (
      <div className="insights-text-wrapper">
        <Tabs>
          {Object.keys(outputData).map((item) => (
            <Tab title={item}>
              <div className="text-container">
                {getInsights(outputData[item])}
              </div>
            </Tab>
          ))}
        </Tabs>
      </div>
    );
  }
};
export default SpeechToText;
