export const langs = {
  af: {
    name: "Afrikaans",
    nativeName: "Afrikaans",
  },
  sq: {
    name: "Albanian",
    nativeName: "Shqip",
  },
  am: {
    name: "Amharic",
    nativeName: "አማርኛ",
  },
  ar: {
    name: "Arabic",
    nativeName: "العربية",
  },
  hy: {
    name: "Armenian",
    nativeName: "Հայերեն",
  },
  az: {
    name: "Azerbaijani",
    nativeName: "azərbaycan dili",
  },
  bn: {
    name: "Bengali",
    nativeName: "বাংলা",
  },
  bs: {
    name: "Bosnian",
    nativeName: "bosanski jezik",
  },
  bg: {
    name: "Bulgarian",
    nativeName: "български език",
  },
  ca: {
    name: "Catalan; Valencian",
    nativeName: "Català",
  },
  zh: {
    name: "Chinese",
    nativeName: "中文 (Zhōngwén), 汉语, 漢語",
  },
  hr: {
    name: "Croatian",
    nativeName: "hrvatski",
  },
  cs: {
    name: "Czech",
    nativeName: "česky, čeština",
  },
  da: {
    name: "Danish",
    nativeName: "dansk",
  },
  nl: {
    name: "Dutch",
    nativeName: "Nederlands, Vlaams",
  },
  en: {
    name: "English",
    nativeName: "English",
  },
  et: {
    name: "Estonian",
    nativeName: "eesti, eesti keel",
  },
  fi: {
    name: "Finnish",
    nativeName: "suomi, suomen kieli",
  },
  fr: {
    name: "French",
    nativeName: "français, langue française",
  },
  ka: {
    name: "Georgian",
    nativeName: "ქართული",
  },
  de: {
    name: "German",
    nativeName: "Deutsch",
  },
  el: {
    name: "Greek, Modern",
    nativeName: "Ελληνικά",
  },
  gu: {
    name: "Gujarati",
    nativeName: "ગુજરાતી",
  },
  ht: {
    name: "Haitian; Haitian Creole",
    nativeName: "Kreyòl ayisyen",
  },
  ha: {
    name: "Hausa",
    nativeName: "Hausa, هَوُسَ",
  },
  he: {
    name: "Hebrew (modern)",
    nativeName: "עברית",
  },
  hz: {
    name: "Herero",
    nativeName: "Otjiherero",
  },
  hi: {
    name: "Hindi",
    nativeName: "हिन्दी, हिंदी",
  },
  hu: {
    name: "Hungarian",
    nativeName: "Magyar",
  },
  is: {
    name: "Icelandic",
    nativeName: "Íslenska",
  },
  it: {
    name: "Italian",
    nativeName: "Italiano",
  },
  ja: {
    name: "Japanese",
    nativeName: "日本語 (にほんご／にっぽんご)",
  },
  kn: {
    name: "Kannada",
    nativeName: "ಕನ್ನಡ",
  },
  kk: {
    name: "Kazakh",
    nativeName: "Қазақ тілі",
  },
  la: {
    name: "Latin",
    nativeName: "latine, lingua latina",
  },
  lv: {
    name: "Latvian",
    nativeName: "latviešu valoda",
  },
  mk: {
    name: "Macedonian",
    nativeName: "македонски јазик",
  },
  ms: {
    name: "Malay",
    nativeName: "bahasa Melayu, بهاس ملايو‎",
  },
  ml: {
    name: "Malayalam",
    nativeName: "മലയാളം",
  },
  mt: {
    name: "Maltese",
    nativeName: "Malti",
  },
  mr: {
    name: "Marathi (Marāṭhī)",
    nativeName: "मराठी",
  },
  mn: {
    name: "Mongolian",
    nativeName: "монгол",
  },
  no: {
    name: "Norwegian",
    nativeName: "Norsk",
  },
  ps: {
    name: "Pashto, Pushto",
    nativeName: "پښتو",
  },
  pt: {
    name: "Portuguese",
    nativeName: "Português",
  },
  ro: {
    name: "Romanian, Moldavian, Moldovan",
    nativeName: "română",
  },
  ru: {
    name: "Russian",
    nativeName: "русский язык",
  },
  sr: {
    name: "Serbian",
    nativeName: "српски језик",
  },
  si: {
    name: "Sinhala, Sinhalese",
    nativeName: "සිංහල",
  },
  sk: {
    name: "Slovak",
    nativeName: "slovenčina",
  },
  sl: {
    name: "Slovene",
    nativeName: "slovenščina",
  },
  so: {
    name: "Somali",
    nativeName: "Soomaaliga, af Soomaali",
  },
  es: {
    name: "Spanish",
    nativeName: "español",
  },
  sw: {
    name: "Swahili",
    nativeName: "Kiswahili",
  },
  ss: {
    name: "Swati",
    nativeName: "SiSwati",
  },
  sv: {
    name: "Swedish",
    nativeName: "svenska",
  },
  ta: {
    name: "Tamil",
    nativeName: "தமிழ்",
  },
  te: {
    name: "Telugu",
    nativeName: "తెలుగు",
  },
  th: {
    name: "Thai",
    nativeName: "ไทย",
  },
  tr: {
    name: "Turkish",
    nativeName: "Türkçe",
  },
  uk: {
    name: "Ukrainian",
    nativeName: "українська",
  },
  ur: {
    name: "Urdu",
    nativeName: "اردو",
  },
  uz: {
    name: "Uzbek",
    nativeName: "zbek, Ўзбек, أۇزبېك‎",
  },
  vi: {
    name: "Vietnamese",
    nativeName: "Tiếng Việt",
  },
  cy: {
    name: "Welsh",
    nativeName: "Cymraeg",
  },
};
