import React from "react";
import { useSelector } from "react-redux";
import { imgMapping } from "../../utils/config/statusMapping.config";
import "./NebulaPredictionOutput.scss";

const NebulaPredictionOutput = ({ useCase, inputText }) => {
	const outputData = useSelector((state) => state.nebulaCategories?.prediction);
	const getComponent = () => {
		const component = imgMapping[useCase]?.component;
		if (component) {
			return React.createElement(component, {
				outputData: outputData,
				inputText: inputText,
				
			});
		}
	};

	return (
		<div className='prediction-output'>
			{Object.entries(outputData).length ? getComponent():""}
			{/* <div className='no-prediction-data'>
					Upload Your Data To See Your Predictions
				</div> */}
		</div>
	);
};

export default NebulaPredictionOutput;
