import React from "react";
import "./PageTitleBanner.scss";

const PageTitleBanner = (props) => {
  const { leftSideProp, rightSideProp } = props;
  return (
    <div className="title-banner-container">
      <div className="left-side">{leftSideProp}</div>
      <div className="right-side">{rightSideProp}</div>
    </div>
  );
};
export default PageTitleBanner;
