import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getResultsText,
  postResultsText,
} from "../../actions/NebCategoriesAction";
import { RequestMethod } from "../../utils/config/nebula-api.config";
import Button from "../button-component/Button";
import Input from "../input-component/Input";
import "./NebulaInput.scss";

function NebulaInput({ useCase, setInputText, langLeftValue, langRightValue }) {
  const [text, setText] = useState("");
  const selectedItem = useSelector((state) => state.nebulaCategories?.service);
  const dispatch = useDispatch();

  const setInput = ({ event }) => {
    setText(event?.target?.value);
  };

  const predict = () => {
    let data = {
      text: text,
    };
    if (useCase === "text_translation" && selectedItem.cloudServiceProvider==="aws") {
      data = {
        text: text,
        source_language_code: langLeftValue,
        target_language_code: langRightValue,
      };
    }

    if (useCase === "text_translation" && selectedItem.cloudServiceProvider==="gcp") {
      data = {
        text: text,
        source_language_code: langLeftValue,
        target_language_code: langRightValue,
      };
    }

    const url = selectedItem.endpoint;
    setInputText(text);
    if (useCase.toLowerCase().includes("text_to_speech")) {
      dispatch(postResultsText(data, url, useCase,RequestMethod.GET));
    } 
    else if(useCase.toLowerCase().includes("pii_redaction") && selectedItem.cloudServiceProvider==="gcp"){
      dispatch(getResultsText(data, url, useCase, RequestMethod.POST));
    }
    else {
      dispatch(getResultsText(data, url, useCase, RequestMethod.GET));
    }
  };

  return (
    <>
      <div className="nebula-input-container">
        <Input
          value={text}
          name="pipelineName"
          onChange={(event) => setInput(event)}
          required
          className="prediction-input"
          labelClassName="prediction-input-label"
          multi="true"
          placeholder="Type Here"
          maxlength={
            useCase.toLowerCase().includes("text_classification") ? "" : "1000"
          }
        />
        <Button
          onClick={predict}
          disabled={!text}
          className="start-prediction-btn"
        >
          Submit
        </Button>
      </div>
    </>
  );
}

export default NebulaInput;
