import React, { useState, useEffect } from "react";
import "./MobiusSidebar.scss"
import selectedIcon from "../../img/selected.svg"


function MobiusSidebar(props) {

  const {title,allcategories, setSelectedCategory,categorySent} = props;
  const [isSelected,setIsSelected] = useState("all")
  const categorySelect = (item) => {
    setSelectedCategory(item);
    setIsSelected(item)
  };

  useEffect(() => {
    if(categorySent){
      setIsSelected(categorySent);
    }
  }, [])
  
  
  return (
    <div className="mobius-sidebar-container">
        <div className="sidebar-title">
        <h2 className="sidebar-title-text">{title || "Categories"}</h2>
        </div>
   
      <div className="sidebar-items">
        {allcategories.map((category) => {
          return (
            <div
              className={`sidebar-item ${isSelected.toLowerCase()===category.toLowerCase()?"selected":""}`}
              onClick={() => categorySelect(category)}
              key={category}
            > {
              isSelected.toLowerCase()===category.toLowerCase() && 
              <img src={selectedIcon} alt="" />
            }
              <p className="sidebar-item-text">{category}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default MobiusSidebar;
