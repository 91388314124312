import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { copyContent, downloadJSON } from "../../functions/nebulaHelper";
import copyIcon from "../../img/copy.svg";
import download from "../../img/download.svg";
import types from "../../types/pipelineListingTypes";
import "./JsonOutput.scss";
const JsonOutput = () => {
	const outputData = useSelector((state) => state.nebulaCategories?.prediction);
	const jsonData = JSON.stringify(outputData, null, 2);
	const dispatch = useDispatch();

	const copyData = () => {
		if (Object.entries(outputData)?.length) {
			dispatch({
				type: types.POPUP_TRIGGER,
				data: {
					message: "Data Copied!!",
					type: "success",
				},
			});
			copyContent(jsonData);
		}
	};
	return (
		<div className='json-output-wrapper'>
			<div className='json-output-container'>
				<h1>Response</h1>
				<img
					src={copyIcon}
					alt=''
					className={`copy-img ${
						Object.entries(outputData)?.length ? "" : "disable-img"
					}`}
					onClick={() => copyData()}
				/>
				<img
					src={download}
					alt=''
					className={`download-img ${
						Object.entries(outputData)?.length ? "" : "disable-img"
					}`}
					onClick={() => downloadJSON(outputData)}
				/>
			</div>
			<div className='json-output'>
				{Object.entries(outputData)?.length ? (
					<pre>{jsonData}</pre>
				) : (
					<pre>"Upload Your Data To See Your Predictions"</pre>
				)}
			</div>
		</div>
	);
};
export default JsonOutput;
