import React from "react";
import { useSelector } from "react-redux";
import PaiProgressBar from "../../pie-progress-bar/PaiProgressBar";

import "./SentimentAnalysis.scss";

function SentimentAnalysis(props) {
  const selectedService = useSelector(
		(state) => state.nebulaCategories.service
	);
  const isGCP = selectedService.cloudServiceProvider === "gcp";
  const sentiment = isGCP ? props?.outputData["response is"]?.sentences : props?.outputData?.Sentiment;
 
  const getAccuracy = (data)=>{
    return data.map(item=>item.sentiment?.score)
  }
  
  const getAWSAccuracy = ()=>{
    return props?.outputData?.SentimentScore[capitalize(props?.outputData?.Sentiment)]
  }
  const accuracy = isGCP ? getAccuracy(sentiment) : getAWSAccuracy()
  
   function capitalize(s) {
      const temp = s?.toLowerCase() || [""];
      return temp[0]?.toUpperCase() + temp.slice(1);
    }
  const getSentiment=()=>{
     const score= getAccuracy(sentiment);
     if(score>0.25 && score<=1){
       return "Positive"
     }
     else if(score<=0.25 && score > -0.25){
       return "Neutral"
     }
     else{
       return "Negative"
     }
  }

  
  return (
    <>
      <div className="sentiment-analysis-wrapper">
        <div>
          <span className="sentiment-analysis-result">
            {isGCP? getSentiment() : sentiment}
          </span>
        </div>
      </div>
      <div className="sentiment-analysis-accuracy">
        <h2>Confidence</h2>
      <PaiProgressBar
				size={35}
				strokeWidth={3}
				percentage={(accuracy*100)}
				color='#39AB5F'
			/>
     
      </div>
    </>
  );
}

export default SentimentAnalysis;
