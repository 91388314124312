import types from "../types/pipelineListingTypes";

const initialState = {
  visible: false,
  type: 'success', // success, warning, error.
  message: ''
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.POPUP_TRIGGER:
    	const { type, message } = action.data
    	return { ...state, visible: true, type, message };
		case types.POPUP_RESET:
    	return { ...initialState };
    default:
      return state;
  }
};

export default reducer;
