import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import types from "../../../types/pipelineListingTypes";
import "./Ocr.scss";

function Ocr() {
  const selectedService = useSelector(
    (state) => state.nebulaCategories?.service
  );

  const image = useSelector((state) => state.nebulaCategories?.document);
  const imgData =
    useSelector((state) => state.nebulaCategories.prediction) || [];
  const document = useSelector((state) => state.nebulaCategories?.document);
  const ocrData = !isFileImage(document) ? imgData?.OCR_Data.split("\n") : imgData;

  function isFileImage(file) {
    return file && file["type"].split("/")[0] === "image";
  }



  return (
    <div className="ocr-wrapper">
      {!isFileImage(document) ? (
        <div className="image-container">
          <object
            id="pdf_content"
            width="100%"
            height="1500px"
            type="application/pdf"
            trusted="yes"
            application="yes"
            title="Assembly"
            data={`${document && URL.createObjectURL(document)}`}
          ></object>
        </div>
      ) : (
        <div className="image-container">
          <img src={image && URL.createObjectURL(image)} alt="uploadedImage" />
        </div>
      )}
      {!isFileImage(document) ? (
        <div className="ocr-container">
          {ocrData?.length &&
            ocrData?.map((item) => <span className="key">{item}</span>)}
        </div>
      ) : (
        <div className="ocr-container">
          {ocrData?.OCR_Data?.length &&
            ocrData?.OCR_Data?.map((item) => (
              <span className="key">{item}</span>
            ))}
        </div>
      )}
    </div>
  );
}

export default Ocr;
