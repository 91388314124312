import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import types from "../../types/pipelineListingTypes.js";

import closeIcon from "../../img/close.svg";
import { nebulaMultiPart, BASE} from "../../utils/config/nebula-api.config.js";
import "./File-upload.scss";

const FileUpload = ({
  submit,
  supportedFilesTypes,
  endpoint,
  handleComplete,
  fileUploadKey,
  setFile,
}) => {
  const [status, setStatus] = useState("Drop Here");
  const [percentage, setPercentage] = useState();
  const [fileWithMetaData, setFileWithMetaData] = useState();
  const selectedService = useSelector(
    (state) => state.nebulaCategories.service
  );
  const dispatch = useDispatch();

  const onDragLeave = (event) => {
    setStatus("Drop Here");
    event.preventDefault();
  };
  const bytesToSize = (bytes) => {
    return `${(bytes / 1024 ** 2).toFixed(1)} MB`;
  };
  const onDragOver = (event) => {
    setStatus("Drop");
    event.preventDefault();
  };

  const handleError = (message) => {
    onRemove();
    dispatch({
      type: types.POPUP_TRIGGER,
      data: {
        message,
        type: "error",
      },
    });
  };

  const onDrop = (event, eventSource) => {
    let file;
    let length;

    if (eventSource && eventSource === "input") {
      file = event.target.files[0];
      length = event.target.files.length;
    } else {
      file = event.dataTransfer.files[0];
      length = event.dataTransfer.files.length;
    }
    if (supportedFilesTypes?.indexOf(file?.type) > -1 && length < 2) {
      setFileWithMetaData(file);
      setFile(file);
    } else {
      handleError("Invalid file");
    }
  };

  const handleUpload = () => {
    let formData = new FormData();
    formData.append("file", fileWithMetaData);
    setStatus("uploading");
    let url;
    if (
      selectedService.title === "document_ai" &&
      selectedService.use_case === "text_extraction"
    ) {
      const params = {
        project_id: "us-gcp-ame-con-be2-npd-1",
        processor_id: "a4a904f8348b1356",
        location: "us",
      };
      url = BASE + selectedService.endpoint + "?" + new URLSearchParams(params);
    } else if (
      selectedService.title === "document_ai_invoice_parser" &&
      selectedService.use_case === "expense_analysis"
    ) {
      const params = {
        project_id: "us-gcp-ame-con-be2-npd-1",
        processor_id: "bd6d9b2ddf7f6710",
        location: "us",
      };
      url = BASE + selectedService.endpoint + "?" + new URLSearchParams(params);
    } else {
      url = BASE + selectedService.endpoint;
    }

    if (endpoint && fileUploadKey) {
      formData.delete("file");
      formData.append(fileUploadKey, fileWithMetaData);
    }
    const newHeaders = nebulaMultiPart()
    const headers = {
      ...newHeaders,
      "Content-Type": "multipart/form-data",
    };

    axios
      .post(endpoint || url, formData, {
        headers,
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setPercentage(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((res) => {
        setStatus("complete");
        dispatch({
          type: types.SET_INPUT_DOCUMENT,
          data: fileWithMetaData,
        });
        if (res?.data) {
          dispatch({
            type: types.SET_TEXT_RESULT,
            data: res.data,
          });
        }
        dispatch({
          type: types.POPUP_TRIGGER,
          data: {
            message: "File Upload successful",
            type: "success",
          },
        });
      })
      .catch((err) => {
        handleError("Unable to upload");
      })
      .finally((_) => handleComplete());
  };

  useEffect(() => {
    if (submit) {
      setStatus("uploading");
      handleUpload();
    } else {
      setStatus("Drop Here");
    }
  }, [submit]);

  const onRemove = () => {
    setStatus("Drop Here");
    setFileWithMetaData(null);
    setPercentage(0);
    setFile(null);
  };

  return (
    <div
      className={`file-upload-wrapper ${status === "drop" ? "over" : ""}`}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      {fileWithMetaData && status !== "uploading" ? (
        <div className="uploaded-file">
          <span className="file-type">
            {fileWithMetaData.type.split("/")[1]}
          </span>
          <div className="file">
            <div className="file-name">{fileWithMetaData.name}</div>
            <div className="file-size">
              {bytesToSize(fileWithMetaData.size)}
            </div>
          </div>
          <img
            onClick={onRemove}
            className="removeIcon"
            src={closeIcon}
            alt="close"
          />
        </div>
      ) : (
        <div className={`drop-area ${status === "uploading" && "progress"}`}>
          {status === "uploading" ? (
            <div className="file-loader">
              <div
                className="progress-bar"
                style={{
                  width: `${status === "uploading" && percentage}%`,
                }}
              ></div>
              <div className="info-section">
                <span className="percentage">{percentage + "%"}</span>
                <span className="sub-text">uploading</span>
              </div>
            </div>
          ) : (
            <div for="file-input">
              <div>
                Drop your file here or <span className="bold">browse</span>
              </div>
              <div className="sub-text">max file size: 10MB</div>
              <input
                id="file-input"
                type="file"
                value={fileWithMetaData}
                onChange={(e) => onDrop(e, "input")}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FileUpload;
