import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./TextTranslation.scss";
import EyeOpen from "../../../img/eye.svg";
import EyeHidden from "../../../img/eye-hidden.svg";

function TextTranslation({ outputData }) {
  const selectedService = useSelector(
    (state) => state.nebulaCategories?.service
  );

  const document = useSelector((state) => state.nebulaCategories?.document);
  const [showTranslate, setShowTranslate] = useState(false);

  const getTranslatedDocument = () => {
    if (showTranslate) {
      return (
        <div className="image-container">
          <object
            id="pdf_content"
            width="100%"
            height="1500px"
            type="application/pdf"
            trusted="yes"
            application="yes"
            title="Assembly"
            data={`${document && URL.createObjectURL(document)}`}
          ></object>
        </div>
      );
    } else {
      return (
        <div className="image-container">
           <object
            id="pdf_content"
            width="100%"
            height="1500px"
            type="application/pdf"
            trusted="yes"
            application="yes"
            title="Assembly"
            data={`data:application/pdf;base64,${outputData?.translated_bytes}`}
          ></object>
        </div>
      );
    }
  };
  if (selectedService?.type === "document") {
    return (
      <div className="translate-img-wrapper">
        <div
          onClick={() => setShowTranslate(!showTranslate)}
          className="secret-wrapper"
        >
          <p className="secret-text">Translated Data</p>
          <img src={showTranslate ? EyeOpen : EyeHidden} alt="" />
        </div>
        {outputData ? <>{getTranslatedDocument()}</> : ""}
      </div>
    );
  } else {
    return (
      <div className="text-translation-wrapper">
        {selectedService.cloudServiceProvider === "aws" ? (
          <span>{outputData?.TranslatedText}</span>
        ) : (
          <span>{outputData}</span>
        )}
      </div>
    );
  }
}

export default TextTranslation;
