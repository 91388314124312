import ImageClassification from "../../img/image-classification.svg";
import FaceDetectionIcon from "../../img/face-detection.svg";
import FaceMaskIcon from "../../img/face-mask.svg";
import NaturalEntityIcon from "../../img/file.svg";
import FaceIcon from "../../img/sentiment.svg";
import PiiIcon from "../../img/pii-prediction.svg";
import translationIcon from "../../img/translate.svg";
import DataExtraction from "../../img/data-filter.svg";
import TextToSpeechIcon from "../../img/text-to-speech.svg";
import SpeechToTextIcon from "../../img/speechtotext.svg";
import ExpenseIcon from "../../img/expense-analysis.svg";
import DominantLanguage from "../../components/usecase-output/dominant-language/DominantLanguage";
import Ner from "../../components/usecase-output/ner/Ner";
import SentimentAnalysis from "../../components/usecase-output/sentiment-analysis/SentimentAnalysis";
import PiiRedaction from "../../components/usecase-output/pii-redaction/PiiRedaction";
import TextTranslation from "../../components/usecase-output/text-translation/TextTranslation";
import FaceDetection from "../../components/usecase-output/face-detection/FaceDetection";
import FaceMask from "../../components/usecase-output/face-mask-detection/FaceMask";
import ObjectDetection from "../../components/usecase-output/object-detection/ObjectDetection";
import { BASE } from "./nebula-api.config";
import TextToSpeech from "../../components/usecase-output/text-to-speech/TextToSpeech";
import LabelDetection from "../../components/usecase-output/label-detection/LabelDetection";
import POS from "../../components/usecase-output/pos-tagging/POS";
import LogoDetection from "../../components/usecase-output/logo-detection/LogoDetection";
import HandwritingDetection from '../../components/usecase-output/handwriting-detection/HandwritingDetection';
import SpeechToText from './../../components/speech-to-text/SpeechToText';
import TextExtraction from "../../components/usecase-output/text-extraction/TextExtraction";
import ExplicitContentDetection from "../../components/usecase-output/explicit-content-detection/ExplicitContentDetection";
import TextClassification from "../../components/usecase-output/text-classification/TextClassification";
import Ocr from "../../components/usecase-output/ocr/Ocr"
import InvoiceDataExtraction from "../../components/usecase-output/invoicedata-extraction/InvoiceDataExtraction";

export const stageValueConfig = {
  DATASET_CREATED: "Data Source Created",
  DATASET_DELETED: "Data Source Deleted",
  IMPORT_DATASET: "Data Source Synced",
  DATA_IMPORTED: "Data Source Deleted",
  MODEL_DEPLOYING: "Model Deployed",
  MODEL_DEPLOYED: "Model Deployed",
  MODEL_UNDEPLOYED: "Model Undeployed",
  MODEL_TRAINING: "New Model Created",
  MODEL_DELETED: "Model Deleted",
  MODEL_EXPORTED: "New Model Created",
  MODEL_FAILED: "Model Training Failed",
  TRAINING_COMPLETED: "New Model Created",
  TRAINING_STOPPED: "Model Training Stopped",
  PROJECT_CREATED: "New Model Created",
  PROJECT_DELETED: "Model Deleted",
  REKOGNITION_PROJECT_CREATED: "New Model Created",
  TRAINED: "New Model Created",
  EXPORT_ANNOTATIONS: "Labelled Data Exported",
  TRANSFORM_ANNOTATIONS: "Labelled Data Exported",
  CREATE_ANNOTATION_PROJECT: "Data Source Synced",
};

export const imgMapping = {
  label_detection: {
    image: ImageClassification,
    type: "image",
    component: LabelDetection,
    desc:"It detects various object, scene, action, and concepts present in the given image",
    file: "JPEG, PNG, JPG"
    
  },
  face_detection: {
    image: FaceDetectionIcon,
    type: "image",
    component: FaceDetection,
    awsUrl: `${BASE}/aws/rekognition/detect_faces`,
    desc: "Model identifies all the faces of different humans present in the input image",
    file: "JPEG, PNG, JPG"

  },
  explicit_content_detection: {
    image: ImageClassification,
    type: "image",
    component: ExplicitContentDetection,
    desc: "Identifies presence or absence of fit to work content in the given image",
    file: "JPEG, PNG, JPG"
  },
  face_mask_detection: {
    image: FaceMaskIcon,
    type: "image",
    component: FaceMask,
    awsUrl: `${BASE}/aws/rekognition/detect_face_mask`,
    desc: "It detects the presence of face mask on human faces in the given image",
    file: "JPEG, PNG, JPG"
  },
  dominant_language: {
    image: ImageClassification,
    type: "text",
    component: DominantLanguage,
    desc: "It checks the language used in the given sentence or document by matching it with various language identifiers",
    file: "TEXT"
  },
  ner: {
    image: NaturalEntityIcon,
    type: "text",
    component: Ner,
    desc: "Checks and identifies various natural life entities such as person, place, organization, etc. present in the given text data",
    file: "TEXT"

  },
  object_detection: {
    image: ImageClassification,
    type: "image",
    component: ObjectDetection,
    gcpUrl:`${BASE}/gcp/vision/object_detection_local`,
    desc: "It detects various real life objects in an image and provides a label to denote the same",
    file: "JPEG, PNG, JPG"
  },
  crop_hints_from_image: {
    image: ImageClassification,
    type: "image",
    desc: "Detecting if the image is cropped from any part",
    file: "JPEG, PNG, JPG"
  },
  sentiment_analysis: {
    image: FaceIcon,
    type: "text",
    component: SentimentAnalysis,
    desc: "Identifies the sentiment intended from the text given as input",
    file: "TEXT"
  },
  pos_tagging: {
    image: ImageClassification,
    type: "text",
    component: POS,
    desc: "Indentifies various words in various parts of speech, i.e. Noun, pronoun, etc. present in the given text",
    file: "TEXT"
  },
  pii_redaction: {
    image: PiiIcon,
    type: "text",
    component: PiiRedaction,
    desc: "Identifying the presence of of the PII in the given data",
    file: "TEXT"
  },
  text_translation: {
    image: translationIcon,
    type: "text",
    component: TextTranslation,
    desc: "Translating the given data from one human language to other",
    file: "TEXT"
  },
  async_text_translation: {
    image: translationIcon,
    type: "",
  },
  async_audio_transcription: {
    image: TextToSpeechIcon,
    type: "audio",
  },
  natural_text_to_speech:{
    image: TextToSpeechIcon,
    type: "text",
    component: TextToSpeech,
    desc: "Converts the given texts into and natural speech",
    file: "TEXT"
  },
  text_to_speech: {
    image: TextToSpeechIcon,
    type: "text",
    component: TextToSpeech,
    desc: "Converting the text, given as input, into an audio format",
    file: "TEXT"
  },
  async_text_to_speech: {
    image: TextToSpeechIcon,
    type: "text",
  },
  ocr: {
    image: ImageClassification,
    type: "image",
    desc: "Read and recognize the text in the image ",
    file: "JPEG, PNG, JPG",
    component: Ocr
  },
  
  ocr_pdf:{
    image: ImageClassification,
    type: "image",
    desc: "Read and recognize the text in the image ",
    file: "PDF",
    component: Ocr
  },
  text_extraction: {
    image: NaturalEntityIcon,
    type: "document",
    component: TextExtraction,
    desc: "Extracting text insights present in the image",
    file: "JPEG, PNG, JPG"
  },
  fraud_detection: {
    image: ImageClassification,
    type: "image",
  },
  forecasting: {
    image: DataExtraction,
    type: "documentt",
  },
  analomy_detection: {
    image: FaceIcon,
    type: "",
  },
  async_document_classification: {
    image: NaturalEntityIcon,
    type: "",
  },
  async_dominant_language_detection: {
    image: ImageClassification,
    type: "text",
  },
  async_entity_detection: {
    image: ImageClassification,
    type: "image",
  },
  async_pii_detection: {
    image: PiiIcon,
    type: "",
  },
  async_sentiment_detection: {
    image: FaceIcon,
    type: "",
  },
  async_topic_detection: {
    image: ImageClassification,
    type: "image",
  },
  async_document_analysis: {
    image: NaturalEntityIcon,
    type: "",
  },
  async_document_text_detection: {
    image: NaturalEntityIcon,
    type: "",
  },
  expense_analysis: {
    image: ExpenseIcon,
    type: "",
    component: InvoiceDataExtraction,
    desc:  "Extracting various useful details and insights from an invoice or a bill",
    file: "JPEG, PDF",

  },
  text_entities: {
    image: NaturalEntityIcon,
    type: "",
  },
  text_syntax: {
    image: ImageClassification,
    type: "image",
  },
  classify_text: {
    image: translationIcon,
    type: "",
  },
  convert_text_to_speech: {
    image: TextToSpeechIcon,
    type: "text",
  },
  speech_to_text: {
    image: SpeechToTextIcon,
    type: "audio",
    component: SpeechToText,
    desc: "Converting the given audio speech into text",
    file: "Mp3, AMR, FLAC, MP3, MP4, Ogg,WebM, WAV"
  },
  logo_detection:{
    image: ImageClassification,
    type: "image",
    component:LogoDetection,
    desc: "Detect and recognise logos present in the image",
    file: "JPEG, PNG, JPG"
  },
  handwriting_recognition: {
    image:  ImageClassification,
    type: "text",
    component: HandwritingDetection,
    desc: "Recognizing the natural human handwriting",
    file: "JPEG, PNG, JPG"
  },
  text_classification:{
    image: NaturalEntityIcon,
    type: "text",
    component: TextClassification,
    desc: "It checks and classifies the given texts into a number of predefined categories such as science, arts, etc",
    file: "TEXT"
  },
  document_pii_redaction:{
    image: ImageClassification,
    component: PiiRedaction,
    desc: "Identifying the presence of of the PII in the given data",
    file: "JPEG, PNG, JPG"
  },
  document_translation: {
    image: ImageClassification,
    component: TextTranslation,
    desc: "Translating the given document from one human language to other",
    file: "PDF"
  },
  sentence_embeddings:{
    image: DataExtraction,
    desc: "Extracting various useful details and insights from an invoice or a bill"
  }
};
