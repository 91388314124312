export const getColor=(confidence)=>{
     if(confidence>0.8){
			 return "accurate"
		 }
		 else if(confidence>=0.5 &&confidence<0.8){
			 return "intermediate"
		 }
		 else{
			 return "danger"
		 }

}


export const getConfidencePercentage=(percentage)=>{
    return (percentage + '').slice(0,3)
}

export const downloadJSON = async(content)=>{
	   if(content){
		const fileName = "JSON_data";
		const json = JSON.stringify(content);
		const blob = new Blob([json],{type:'application/json'});
		const href = await URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = href;
		link.download = fileName + ".json";
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);	
	   }  
}


export const copyContent = async (content) => {
	if(content){
    let textArea = document.createElement("textarea");
    textArea.style.position = "fixed";
    textArea.style.top = 0;
    textArea.style.left = 0;
    textArea.style.width = "2em";
    textArea.style.height = "2em";
    textArea.style.padding = 0;
    textArea.style.background = "transparent";
    textArea.value = content;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand("copy");
    } catch (err) {
      console.log("unable to copy");
    }
    document.body.removeChild(textArea);
}
  };

 export const getCoordinates = (data) => {
    const [pt1, pt2, pt3] = data;
    const width = (pt2.x - pt1.x) * 100 + "%";
    const height = (pt3.y - pt2.y) * 100 + "%";
    const left = pt1.x * 100 + "%";
    const top = pt1.y * 100 + "%";

    return { width, height, left, top };
  };