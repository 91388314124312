import _ from "lodash";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllServices } from "../../actions/NebCategoriesAction";
import Button from "../../components/button-component/Button";
import CategoryCard from "../../components/category-card/CategoryCard";
import Input from "../../components/input-component/Input";
import MobiusSidebar from "../../components/mobius-sidebar/MobiusSidebar";
import PageTitleBanner from "../../components/page-title-banner-component/PageTitleBanner";
import types from "../../types/pipelineListingTypes";
import Footer  from "../../components/footer-component/Footer.jsx"
import "./NebulaCategory.scss";
import { imgMapping } from "../../utils/config/statusMapping.config";


function NebulaCategory(props) {
  const allcategories = ["All", "Image", "Text", "Document", "Audio"];
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.nebulaCategories.categories);
  const categorySelected = props.location.state ? props.location.state.categorySelected : 'all';

  useEffect(() => {
    if (categories.length === 0) {
      dispatch(getAllServices());
    }

    if(categorySelected){
      setSelectedCategory(categorySelected);
    }
  }, []);

  const filterUseCases = () => {
    const categoriesData = _.uniqBy(categories, (e) => e.use_case).filter(
      (data) => !data.use_case.includes("async")
    );
    if (selectedCategory.toLowerCase() === "all") {
      return categoriesData.filter((data) =>
        data.use_case?.toLowerCase().includes(search.toLowerCase())
      );
    } else {
      return categoriesData.filter(
        (data) =>
          data.data_type.toLowerCase() === selectedCategory.toLowerCase() &&
          data.use_case?.toLowerCase().includes(search.toLowerCase())
      );
    }
  };

  const textOnChange = ({ event }) => {
    const { value = "" } = event.target;
    setSearch(value);
  };
  const handleRedirect = (item) => {
    dispatch({
      type: types.SET_SELECTED_CATEGORY,
      data: item,
    });
    props.history.push({ 
      pathname: `${item.use_case}`,
      state: {categorySent: selectedCategory}
     });
  };
  return (
    <>
      <PageTitleBanner
        leftSideProp={<div>Nebula</div>}
        rightSideProp={
          <a style={{textDecoration:'none'}} href="https://mobius.aistudio-deloitte.com/#demo" target="_blank" rel="noreferrer">
          <Button className="category-page-btn">Request Demo</Button>
          </a>
        }
      />

      <div className="category-page-container">
        <MobiusSidebar
          allcategories={allcategories}
          title="Select Your Category"
          setSelectedCategory={setSelectedCategory}
          className="category-page-sidebar"
          categorySent={categorySelected}
        />

        <div className="category-page-categories">
          <div className="category-page-search">
            <Input
              className="category-page-search-input"
              placeholder={`Search all usecase`}
              onChange={(event) => textOnChange(event)}
              labelClassName = "category-label-search"
              type="search"

            />
          </div>

          <div className="category-row">
            {filterUseCases().map((use) => (
              (imgMapping[use.use_case]?.component) &&
              <CategoryCard
                key={use.use_case}
                useCase={use}
                handleRedirect={() => handleRedirect(use)}
                desc={imgMapping[use.use_case]?.desc}
              />
            ))}
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
}

export default withRouter(NebulaCategory);
