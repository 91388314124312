import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import logger from "redux-logger";
import { createMemoryHistory } from "history";
import rootReducer from "./rootReducer";

export const history = createMemoryHistory({
  initialEntries: ["/"],
  initialIndex: 0,
});

const rootReducerImport = require("./rootReducer");
// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// eslint-disable-next-line import/no-mutable-exports
let store = {};
if (
  process.env.NODE_ENV === "development" ||
  process.env.NODE_ENV === "hybrisdev"
) {
  store = createStore(
    rootReducer(history),
    compose(composeEnhancers(applyMiddleware(thunkMiddleware, logger)))
  );
  module.hot.accept(rootReducerImport, () => {
    const nextRootReducer = rootReducerImport.default;
    store.replaceReducer(nextRootReducer(history));
  });
} else {
  store = createStore(rootReducer(history), applyMiddleware(thunkMiddleware));
}

export default store;
