export const BASE = process.env.REACT_APP_BASE_URL;

export const RequestMethod = {
	GET: "GET",
	POST: "POST",
	DELETE: "DELETE",
  };
  
export const nebulaHeader = () => {
	const access_token = sessionStorage.getItem("access_token_nebula");
	return {
		Authorization: `Bearer ${access_token}`,
		"Content-Type": "application/json",
		"Access-Control-Allow-Origin": "*",
		"Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
		"Access-Control-Allow-Headers":
			"Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
		Accept: "application/json",
	};
};
export const nebulaMultiPart = () => {
	const access_token = sessionStorage.getItem("access_token_nebula");
	return {
		Authorization: `Bearer ${access_token}`,
		"Access-Control-Allow-Origin": "*",
		Accept: "application/json",
	};
};

export const NEBULA_URL = {
	listAllServices: `${BASE}/mobius_inference_server/services/by_process_type_and_state?status=active&process_type=sync`,
	listAllActiveServices: `${BASE}/mobius_inference_server/services/by_process_type_and_state?status=active&process_type=sync`,
	signIn: `${BASE}/user/login`,
};
