import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NebulaCategorySidebar from "../../components/nebula-category-sidebar/nebula-category-sidebar";
import NebulaInput from "../../components/nebula-input/NebulaInput";
import { getAllServices } from "../../actions/NebCategoriesAction";
import BackArrowIcon from "../../img/back-arrow.svg";
import NebulaPrediction from "../../components/nebula-prediction/NebulaPrediction";
import types from "../../types/pipelineListingTypes";
import "./NebulaCategoryDetails.scss";
import TextTranslationHeader from "../../components/text-translation-header/TextTranslationHeader";
import PageTitleBanner from "../../components/page-title-banner-component/PageTitleBanner";
import Button from "../../components/button-component/Button";
import Footer from "../../components/footer-component/Footer.jsx"
import NebulaDocumentUpload from "../../components/nebula-upload-doc/NebulaDocumentUpload";

const NebulaCategoryDetails = (props) => {
  const [inputText, setInputText] = useState("");
  const categories = useSelector((state) => state.nebulaCategories.categories);
  const selectedService = useSelector((state) => state.nebulaCategories.service);
  const dispatch = useDispatch();
  const useCase = props.match.params.category;

  const [langLeftValue, setLangLeftValue] = useState("en");

  const [langRightValue, setLangRightValue] = useState("es");
  const categorySent = props.location.state.categorySent;
  
  useEffect(() => {
    dispatch({
      type: types.RESET_LABEL_DATA,
    });
    if (categories.length === 0) {
      dispatch(getAllServices());
    }
  }, []);

  const returnToCategoryList = () => {
    props.history.push({ 
      pathname: '/nebula/allCategories',
      state: {categorySelected: categorySent}
     });
  };

  return (
    <>
      <PageTitleBanner
        leftSideProp={<div>Nebula</div>}
        rightSideProp={
          <a style={{textDecoration:'none'}} href="https://mobius.aistudio-deloitte.com/#demo" target="_blank" rel="noreferrer">
         <Button className="page-header-create-btn">Request Demo</Button>
          </a>
          // <Button className="page-header-create-btn">Request Demo</Button>
        }
      />
      <div className="category-detail-container">
      <div className="nebula-title-wrapper">
        <img src={BackArrowIcon} alt="" onClick={returnToCategoryList} />
        <h1 className="nebula-details-title" onClick={returnToCategoryList}>
          {selectedService.name}
        </h1>
      </div>
      <div className="nebula-details-wrapper">
        <div className="sidebar">
          <NebulaCategorySidebar useCase={useCase} options={categories} />
        </div>
        <div className="main-content">
          <div className="input-section">
            { useCase.toLowerCase().includes("translation") ? (
              <TextTranslationHeader
                langLeftValue={langLeftValue}
                langRightValue={langRightValue}
                setLangLeftValue={setLangLeftValue}
                setLangRightValue={setLangRightValue}
              />
            ) : (
              ""
            )}
            {selectedService.type === "text" || useCase.toLowerCase().includes('text_to_speech') ? (
              <NebulaInput
                useCase={useCase}
                setInputText={setInputText}
                langLeftValue={langLeftValue}
                langRightValue={langRightValue}
              />
            ) : (
             <NebulaDocumentUpload 
             useCase={useCase}   
             langLeftValue={langLeftValue}
             langRightValue={langRightValue}/>
            )}
          </div>
          <div className="output-section">
            <NebulaPrediction useCase={useCase} inputText={inputText} />
          </div>
        </div>
      </div>
      </div>
      <Footer/>
    </>
  );
};

export default withRouter(NebulaCategoryDetails);
