import React from "react";
import "./PaiProgressBar.scss";
const PaiProgressBar = ({ size, strokeWidth, percentage, color }) => {
	const viewBox = `0 0 ${size} ${size}`;
	const radius = (size - strokeWidth) / 2;
	const circumference = radius * Math.PI * 2;
	const dash = (percentage * circumference) / 100;

	return (
		<div className='pie-progress-bar'>
			<div className='pie-progress-bar-text'>
				<svg width={size} height={size} viewBox={viewBox}>
					<circle
						fill='none'
						stroke={color}
						cx={size / 2}
						cy={size / 2}
						r={radius}
						strokeWidth={`${strokeWidth}px`}
						transform={`rotate(-90 ${size / 2} ${size / 2})`}
						strokeDasharray={[dash, circumference - dash]}
						strokeLinecap='round'
					/>
				</svg>
				<span>{((percentage / 100)+"").substr(0,3)}</span>
			</div>
		</div>
	);
};
export default PaiProgressBar;
