import React from "react";
import { useSelector } from "react-redux";
import "./InvoiceDataExtraction.scss";

const InvoiceDataExtraction = (props) => {
  const selectedService = useSelector(
    (state) => state.nebulaCategories?.service
  );
  const isDocument = selectedService.type === "document";
  const outputData = useSelector(
    (state) => state.nebulaCategories?.prediction?.Processed_Data.Key_Value_Pair
  );
  const image = useSelector((state) => state.nebulaCategories.document);
  const document = useSelector((state) => state.nebulaCategories?.document);

  function isFileImage(file) {
    return file && file["type"].split("/")[0] === "image";
  }

  return (
    <div className="invoice-extraction-wrapper">
      <div className="image-container">
        {!isFileImage(document) ? (
          <object
            id="pdf_content"
            width="100%"
            height="1500px"
            type="application/pdf"
            trusted="yes"
            application="yes"
            title="Assembly"
            data={`${document && URL.createObjectURL(document)}`}
          ></object>
        ) : (
          <img src={image && URL.createObjectURL(image)} alt="uploadedImage" />
        )}
      </div>

      <div className="text-container">
        {outputData?.map((item) => {
          if (item.Value.Text) {
            return (
              <div className="key-value-pair">
                <span className="key">{item.Key.Text}</span>{" "}
                <span className="value">{item.Value.Text}</span>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};
export default InvoiceDataExtraction;
