import _ from "lodash";
import types from "../../../types/pipelineListingTypes";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getColor,
  getConfidencePercentage,
} from "../../../functions/nebulaHelper";
import "./ObjectDetection.scss";

function ObjectDetection() {
  const image = useSelector((state) => state.nebulaCategories.document);
  const dispatch = useDispatch();
  const selectedService = useSelector(
    (state) => state.nebulaCategories.service
  );
  const isGCP = selectedService.cloudServiceProvider === "gcp";
  const responseGCP =
    useSelector(
      (state) =>
        state.nebulaCategories.prediction["response is"]
          ?.localizedObjectAnnotations
    ) || [];
  const responseAWS = useSelector(
    (state) => state.nebulaCategories.prediction?.Labels
  );
  const objData = isGCP ? responseGCP : responseAWS;
  

  useEffect(() => {
    setLabels();
  }, [objData]);

  const getCordinates = (data) => {
    if (isGCP) {
      const [pt1, pt2, pt3] = data;
      const width = (pt2.x - pt1.x) * 100 + "%";
      const height = (pt3.y - pt2.y) * 100 + "%";
      const left = pt1.x * 100 + "%";
      const top = pt1.y * 100 + "%";

      return { width, height, left, top };
    } else {
      const { Height, Width, Left, Top } = data.BoundingBox;
      return {
        height: (Height * 100).toFixed(2) + "%",
        width: (Width * 100).toFixed(2) + "%",
        left: (Left * 100).toFixed(2) + "%",
        top: (Top * 100).toFixed(2) + "%",
      };
    }
  };

  const setLabels = () => {
    let labels = [];
    if(isGCP){
    objData.forEach((obj) => {
      labels.push(obj.name);
    });
    dispatch({
      type: types.SET_LABEL_DATA,
      data: _.uniq(_.map(labels)),
    });
  }else{
    objData.forEach((obj) => {
      labels.push(obj.Name);
    });
    dispatch({
      type: types.SET_LABEL_DATA,
      data: _.uniq(_.map(labels)),
    });
  }
  };
  return (
    <div className="obj-detection-wrapper">
      <div className="obj-detection-container">
        <img
          src={image && URL.createObjectURL(image)}
          alt=""
          className="obj-detection-output"
        />
        {isGCP
          ? objData?.map((obj) => {
              const getClass = getColor(obj.score);
              return (
                <div
                  className={`obj-frame ${getClass}`}
                  style={{
                    ...getCordinates(obj.boundingPoly.normalizedVertices),
                  }}
                >
                  <span className={`frame-title ${getClass}`}>{obj.name}</span>
                  <span className={`frame-confidence ${getClass}`}>
                    {getConfidencePercentage(obj.score)}
                  </span>
                </div>
              );
            })
          : objData?.map((obj) => {
              if (obj.Instances?.length) {
                return obj.Instances.map((instance) => {
                  const getClass = getColor(instance.Confidence);
                  return (
                    <div
                      className={`obj-frame ${getClass}`}
                      style={{
                        ...getCordinates(instance),
                      }}
                    >
                      <span className={`frame-title ${getClass}`}>
                        {obj.Name}
                      </span>
                      <span className={`frame-confidence ${getClass}`}>
                        {getConfidencePercentage(instance.Confidence/100)}
                      </span>
                    </div>
                  );
                });
              }
            })}
      </div>
    </div>
  );
}

export default ObjectDetection;
