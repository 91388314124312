import { Route, Switch } from "react-router-dom";
import { ProtectedRouteNB } from "./protectedNB.route.js";
import "./App.scss";
import "./styles/styles.scss";
import SignInPage from "./pages/sign-in/SignInPage";
import Header from "./components/header-component/Header";
// import Footer from "./components/footer-component/Footer";
import NebulaCategory from "./pages/nebula-category/NebulaCategory";
import NebulaCategoryDetails from "./pages/nebula-category-details/NebulaCategoryDetails";

function App() {
	return (
		<div className='App'>
			<Header />
			<div className='app-body'>
				<Switch>
					<Route exact path='/' component={SignInPage} />
					<Route exact path='/signin' component={SignInPage} />

					<Route
						exact
						path='/nebula/signin'
						render={(props) => <SignInPage type='nebula' {...props} />}
					/>

					<ProtectedRouteNB
						exact
						path='/nebula/allCategories'
						component={NebulaCategory}
					/>
					<ProtectedRouteNB
						exact
						path='/nebula/:category'
						component={NebulaCategoryDetails}
					/>

					<Route path='*' component={() => "404 NOT FOUND"} />
				</Switch>
				{/* <Footer /> */}
			</div>
		</div>
	);
}

export default App;
