import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BASE } from "../../utils/config/nebula-api.config";
import Button from "../button-component/Button";
import FileUpload from "../file-upload/File-upload";
import "./NebulaDocumentUpoad.scss";

function NebulaDocumentUpload({ useCase, langLeftValue, langRightValue }) {
  const [submit, setSubmit] = useState(false);
  const [file, setFile] = useState();
  const selectedService = useSelector(
    (state) => state.nebulaCategories.service
  );
  const [endpoint, setEnpoint] = useState("");
  const supportedFilesTypes = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/tiff",
    "application/pdf",
    "audio/mpeg",
    "audio/mpeg3",
    "audio/x-mpeg",
    "audio/x-mpeg-3",
    "audio/wav",
  ];

  useEffect(() => {
    if (useCase === "document_translation") {
      setEnpoint(
        BASE +
          selectedService?.endpoint +
          "?" +
          new URLSearchParams({
            target_language_code: langRightValue,
            source_language_code: langLeftValue
          })
      );
    }
  }, [selectedService.endpoint, langLeftValue ,  langRightValue]);

  return (
    <div className="document-upload-wrapper">
      <div className="document-upload-container">
        <FileUpload
          useCase={useCase}
          submit={submit}
          supportedFilesTypes={supportedFilesTypes}
          handleComplete={() => setSubmit(false)}
          endpoint={endpoint}
          setFile={setFile}
        />
      </div>
      <Button
        onClick={() => setSubmit(true)}
        className="document-upload-btn"
        disabled={!file}
      >
        Submit
      </Button>
    </div>
  );
}

export default NebulaDocumentUpload;
