import React, { useEffect } from "react";
import _ from "lodash";
import types from "../../../types/pipelineListingTypes";
import { useDispatch, useSelector } from "react-redux";
import {
  getColor,
} from "../../../functions/nebulaHelper";
import "./LabelDetection.scss";

function LabelDetection() {
  const image = useSelector((state) => state.nebulaCategories?.document);
  const selectedService = useSelector(
    (state) => state.nebulaCategories.service
  );
  const isGCP = selectedService.cloudServiceProvider === "gcp";
  const dispatch = useDispatch();
  const responseAWS =
    useSelector((state) => state.nebulaCategories.prediction?.Labels) || [];
  const responseGCP =
    useSelector(
      (state) =>
        state.nebulaCategories.prediction["response is"]?.labelAnnotations
    ) || [];

  let labelData = isGCP ? responseGCP : responseAWS;

  useEffect(() => {
    setLabels();
  }, []);

  const setLabels = () => {
    let labels = [];
    if (isGCP) {
      labelData.forEach((obj) => {
        labels.push(obj.description);
      });
      dispatch({
        type: types.SET_LABEL_DATA,
        data: _.uniq(_.map(labels)),
      });
    } else {
      labelData.forEach((obj) => {
        labels.push(obj.Name);
      });
      dispatch({
        type: types.SET_LABEL_DATA,
        data: _.uniq(_.map(labels)),
      });
    }
  };

  return (
    <div className="label-detection-wrapper">
      <div className="image-container">
        <img src={image && URL.createObjectURL(image)} alt="uploadedImage" />
      </div>
      <div className="label-container">
        {labelData.map((obj) =>
          isGCP ? (
            <div className={`${getColor(obj.score)} confidence`}>
              <span className="key">{obj.description}</span>
            </div>
          ) : (
            <div className={`${getColor(obj.Confidence / 100)} confidence`}>
              <span className="key">{obj.Name}</span>
            </div>
          )
        )}
      </div>
    </div>
  );
}

export default LabelDetection;
