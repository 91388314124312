import React from "react";
import { withRouter } from "react-router-dom";
import "./Footer.scss";

const Footer = ({history, footerType="footer-light"}) => {
  const darkThemePaths = ['/signin', '/signup', '/', '/nebula/signin']
  return (
    <div className="footer-container">
      <div className={ darkThemePaths.indexOf(history.location.pathname) > -1 ? 'footer-dark' : 'footer-light'}>
        <span className="copyright-year">
          @Copyright {new Date().getFullYear()}
        </span>
        <span className="studio-info">{process.env.REACT_APP_ORG}</span>
      </div>
    </div>
  );
};

export default withRouter(Footer);
