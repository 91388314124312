import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

export const ProtectedRouteNB = ({ component: Component, ...rest }) => {

	const nebulaUserInfo =
		useSelector((state) => state?.loginUser?.nebulaUser) ||
		sessionStorage.getItem("access_token_nebula");

	const getTokenFromSession = () => {
		return (
			sessionStorage.getItem("access_token_nebula")
		);
	};

	return (
		<Route
			{...rest}
			render={(props) => {
				if (
					nebulaUserInfo.access_token ||
					getTokenFromSession()
				) {
					return <Component {...rest} {...props} />;
				} else {
					return (
						<Redirect
							to={{
								pathname: "/",
								state: {
									from: props.location,
								},
							}}
						/>
					);
				}
			}}
		/>
	);
};
