import React from "react";
import ImagesIcon from "../../img/image-classification.svg";
import { imgMapping } from "../../utils/config/statusMapping.config";
import "./CategoryCard.scss";

function CategoryCard(props) {
	const { use_case, use_case_name} = props.useCase;
	return (
		<div
			className='category-card-container'
			onClick={() => (imgMapping[use_case]?.component)?props.handleRedirect():null}
			disabled= {!(imgMapping[use_case]?.component)}
			>
			<div className="back"></div>
			<div className="front">
			<img src={imgMapping[use_case]?.image || ImagesIcon} alt='' />
			<h2 className='category-card-title'>{use_case_name}</h2>
			<p className='category-card-text'>
				{props.desc}
			</p>

			</div>


			
		</div>
	
	);
}

export default CategoryCard;
