import _ from "lodash";
import types  from "../../../types/pipelineListingTypes";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getColor,
  getConfidencePercentage,
} from "../../../functions/nebulaHelper";
import "./LogoDetection.scss"

function LogoDetection() {
    const image = useSelector((state) => state.nebulaCategories.document);
    const dispatch = useDispatch()
    const logoData =
      useSelector(
        (state) =>
          state.nebulaCategories.prediction["response is"]
            ?.logoAnnotations
      ) || [];
    
  useEffect(()=>{
    setLabels();
  },[logoData])
    
  const getCordinates = (data) => {
      const [pt1, pt2, pt3] = data;
      const width = (pt2.x - pt1.x)+"px";
      const height = (pt3.y - pt2.y)+ "px";
      const left = pt1.x + "px";
      const top = pt1.y + "px";
  
      return { width, height, left, top };
    };
  
    const setLabels= () =>{
        let labels = []
        logoData.forEach(obj=>{
            labels.push(obj.description)
        })
      dispatch({
          type: types.SET_LABEL_DATA,
          data: _.uniq(_.map(labels)),
      });
    }
    return (
      <div className="logo-detection-wrapper">
        <div className="logo-detection-container">
          <img
            src={URL.createObjectURL(image)}
            alt=""
            className="logo-detection-output"
          />
          {logoData?.map((obj) => {
            const getClass = getColor(obj.score);
            return (
              <div
                className={`obj-frame ${getClass}`}
                style={{ ...getCordinates(obj.boundingPoly.vertices) }}
              >
                <span className={`frame-title ${getClass}`}>{obj.description}</span>
                <span className={`frame-confidence ${getClass}`}>
                  {getConfidencePercentage(obj.score)}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

export default LogoDetection
