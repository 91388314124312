import React, { useState } from "react";

import "./Tabs.scss";
function Tabs(props) {
  const [selected, setSelected] = useState(props.selected || 0);

  const handleChange = (index) => {
    setSelected(index);
  };

  return (
    <>
    <div className="mobius-tabs-wrapper">
      <ul className="mobius-tabs">
        {props?.children.map((elem, index) => {
          let style = index === selected ? "selected" : "";
          return (
            <li
              key={index}
              className={style}
              onClick={() => handleChange(index)}
            >
              {elem?.props?.title}
            </li>
          );
        })}
      </ul>
      </div>
      <div className="tab">{props?.children[selected]}</div>
    </>
  );
}

export default Tabs;
