import React from "react";
import { useSelector } from "react-redux";
import downloadIcon from "../../../img/download.svg"
import "./TextToSpeech.scss";

function TextToSpeech({ outputData }) {
  const selectedService = useSelector(
    (state) => state.nebulaCategories.service
  );
  const isGCP = selectedService.cloudServiceProvider === "gcp";
  if (isGCP) {
    return (
      <>
        <div className="text-speech-container">
          {outputData ? (
            <>
            <a href={`data:audio/ogg;base64,${outputData["response is"]?.audioContent}`} download="filename.mp3"><img src={downloadIcon} alt="" /></a>
            <div className="text-speech-wrapper">
            <audio
              controls
              src={`data:audio/ogg;base64,${outputData["response is"]?.audioContent}`}
            />
            </div>
            </>
          ) : null}
        </div>
      </>
    );
  } else {
    return(
    <>
    <div className="text-speech-container">
      {outputData ? (
        <>
         <a href={`data:audio/ogg;base64,${outputData?.speech_data}`} download="filename.mp3"><img src={downloadIcon} alt="" /></a>
        <div className="text-speech-wrapper">
        <audio
          controls
          src={`data:audio/ogg;base64,${outputData?.speech_data}`}
        />
       </div>
       
        </>
      ) : null}
    </div>
  </>
    )
  }
}

export default TextToSpeech;
