import React, { useState } from "react";
import { useDispatch} from "react-redux";
import { Redirect } from "react-router-dom";
import { nebulaUserLogin } from "../../actions/loginAction";
import Button from "../../components/button-component/Button";
import Input from "../../components/input-component/Input";
import Footer from "../../components/footer-component/Footer.jsx";

import "./SignInPage.scss";

const SignInPage = (props) => {
	const [userName, setUserName] = useState("");
	const [password, setPassword] = useState();
	const dispatch = useDispatch();

	const onChangeUser = ({ event }) => {
		setUserName(event?.target?.value);
	};

	const onChangePassword = ({ event }) => {
		setPassword(event?.target?.value);
	};
	const signInUser = (e) => {
		e.preventDefault();
		let urlencoded = new URLSearchParams();
		urlencoded.append("username", userName);
		urlencoded.append("password", password);
			dispatch(nebulaUserLogin(urlencoded)).then(res => {
				sessionStorage.setItem("access_token_nebula", res?.access_token);
			sessionStorage.setItem("userName_nebula", res?.name);
				props.history.push("/nebula/allCategories");
			});		
	};

	if (sessionStorage.getItem("access_token_nebula")) {
		return <Redirect to={"/nebula/allCategories"} />;
	}
	return (
		<>
			<div className='signin_page'>
				<div className='signin_page-container'>
					<h1 className='signin_page-container-heading'>Welcome to mObius - Nebula!</h1>
					<div className='signin_page-container-form'>
						<form onSubmit={signInUser}>
							<Input
								label='Username'
								name='userName'
								onChange={(event) => onChangeUser(event)}
								className='signin_page-container-input'
								labelClassName='signin_page-container-form-label'
								required
								theme='dark'
							/>
							<Input
								label='Password'
								name='password'
								onChange={(event) => onChangePassword(event)}
								type='password'
								className='signin_page-container-input'
								labelClassName='signin_page-container-form-label'
								required
								theme='dark'
							/>
							<Button
								type='submit'
								className='signin_page-container-button'
								disabled={!(userName && password)}
							>
								Sign In
							</Button>
						</form>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};
export default SignInPage;
