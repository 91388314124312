import React, { useState, useEffect } from "react";
import Dropdown from "../../components/dropdown-component/Dropdown";
import "./nebula-category-sidebar.scss";
import { useDispatch, useSelector } from "react-redux";
import types from "../../types/pipelineListingTypes";
import { imgMapping } from "../../utils/config/statusMapping.config";


function NebulaCategorySidebar(props) {
	const { useCase, options } = props;
	const [dropdownOptions, setDropdownOptions] = useState([]);
	const [isListOpen, setIsListOpen] = useState(false);
	const selectedItem = useSelector((state) => state.nebulaCategories?.service);
	const dispatch = useDispatch();
	const fileSupport = imgMapping[useCase]?.file.split(",")

	useEffect(() => {
		let getOptions = [];
		options
			.filter((entry) => entry.use_case === useCase)
			.forEach((entry) => {
				getOptions.push({
					id: entry.service_id,
					title: entry.service_name,
					type: entry.data_type,
					use_case: entry.use_case,
					cloudServiceProvider: entry.cloud_service_provider,
					endpoint: entry.endpoint_name,
					name: entry.use_case_name
				});
			});
		setDropdownOptions(getOptions);
		if (options.length > 0) {
			setItemToStore(getOptions[0]);
		}
	}, [options]);

	const handleSelect = (id) => {
		setItemToStore(
			dropdownOptions.find((entry) => entry.id  === id)
		);
	};

	const setItemToStore = (data) => {
		dispatch({
			type: types.SET_SERVICE,
			data,
		});
		dispatch({
			type: types.RESET_LABEL_DATA
		})
	};
	return (
		<div className='nebula-category-sidebar'>
			<div className='dropdown-wrapper'>
				<Dropdown
					className='model-dropdown'
					list={dropdownOptions}
					title={selectedItem?.title?.replaceAll("_", " ")}
					isListOpen={isListOpen}
					setIsListOpen={setIsListOpen}
					setSelectedItem={(e) => handleSelect(e)}
					selectedItem={selectedItem}
					showServiceProviderImg={true}
				/>
			</div>
			<div className='sub-text'>
				{imgMapping[useCase]?.desc}
			</div>
			<div className='label-section'>
				<span className='title'>Supported File Types</span>
				<div className='labels'>
					{
					  fileSupport.map(item=><span>{item}</span>)
					}
						
				</div>
			</div>
		</div>
	);
}

export default NebulaCategorySidebar;
