import React from "react";
import { useSelector } from "react-redux";
import "./TextExtraction.scss";

function TextExtraction() {
  const outputData = useSelector(
    (state) => state.nebulaCategories?.prediction?.Processed_Data.Key_Value_Pair
  );
  const image = useSelector((state) => state.nebulaCategories.document);

  return (
    <div className="text-extraction-wrapper">
      <div className="image-container">
        <img src={image && URL.createObjectURL(image)} alt="uploadedImage" />
      </div>

      <div className="text-container">
        {outputData?.map(
          (item) =>
            item.Value.Text && (
              <div className="key-value-pair">
                <span className="key">{item.Key.Text}</span>{" "}
                <span className="value">{item.Value.Text}</span>
              </div>
            )
        )}
      </div>
    </div>
  );
}
export default TextExtraction;
