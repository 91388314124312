import React, { useState } from "react";

import NebulaPredictionOutput from "../nebula-prediction-output/NebulaPredictionOutput";
import JsonOutput from "../json-output/JsonOutput";
import "./NebulaPrediction.scss";

const stepperData = [
	{
		id: 1,
		description: "Output",
	},
	{
		id: 2,
		description: "JSON",
	},
];

function NebulaPrediction(props) {
	const [id, setId] = useState(1);

	const renderSwitch = (param) => {
		switch (param) {
			case 1:
				return <NebulaPredictionOutput {...props} />;
			case 2:
				return <JsonOutput {...props} />;
			default:
				return;
		}
	};

	return (
		<div className='nebula-prediction-container'>
			<div className='nebula-prediction-tabs'>
				<div className='tab-header'>
					{stepperData.map((step) => (
						<div
							key={step.id}
							className={`tab ${step.id === id && "selected"}`}
							onClick={() => setId(step.id)}>
							{step.description}
						</div>
					))}
					<div className='empty-tab'></div>
				</div>
				<div className='nebula-prediction-tab '>{renderSwitch(id)}</div>
			</div>
		</div>
	);
}

export default NebulaPrediction;
