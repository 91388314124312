import React, { useState } from "react";
import { getColor } from "../../../functions/nebulaHelper";
import EyeOpen from "../../../img/eye.svg";
import EyeHidden from "../../../img/eye-hidden.svg";
import "./PiiRedaction.scss";
import { useSelector } from "react-redux";

function PiiRedaction(props) {
  const [showPII, setShowPII] = useState(false);
  const selectedService = useSelector(
    (state) => state.nebulaCategories.service
  );
  const image = useSelector((state) => state.nebulaCategories.document);
  const tagEntities = (input) => {
    let outputString = input;
    let logs = [];
    props.outputData?.Entities?.forEach((entity, i) => {
      const { BeginOffset, EndOffset, Type } = entity;

      let className = getColor(entity.Score);

      logs.push({
        tag: `--${i}--`,
        html: `<div class="entity-tag ${className}"><span class="entity-text-span ${
          showPII ? "" : "hidePII"
        }">${
          showPII ? input.substring(BeginOffset, EndOffset) : "[ " + Type + " ]"
        }</span>
            </div>`,
      });
      outputString = outputString?.replaceAll(
        input.substring(BeginOffset, EndOffset),
        `--${i}--`
      );
    });

    return { outputString: outputString, logs };
  };
  const replaceEntities = (input) => {
    if (selectedService.cloudServiceProvider === "aws") {
      let { outputString, logs } = tagEntities(input);
      logs.forEach((element) => {
        outputString = outputString?.replace(
          new RegExp(`${element.tag}`, "gi"),
          element.html
        );
      });
      return outputString;
    } else {
      return showPII ? input : props.outputData?.redacted_text;
    }
  };

  const getRedactedImage = () => {
    if (!showPII) {
      return (
        <div className="image-container">
          <img
            src={`data:image/png;base64,${props.outputData?.redacted_image}`}
            alt="uploadedImage"
          />
        </div>
      );
    } else {
      return (
        <div className="image-container">
          <img src={image && URL.createObjectURL(image)} alt="uploadedImage" />
        </div>
      );
    }
  };
  if (selectedService.type === "document") {
    return (
      <div className="pii-img-wrapper">
        <div onClick={() => setShowPII(!showPII)} className="secret-wrapper">
          <p className="secret-text">PII Data</p>
          <img src={showPII ? EyeOpen : EyeHidden} alt="" />
        </div>
        {props.outputData ? <>{getRedactedImage()}</> : ""}
      </div>
    );
  } else {
    return (
      <div className="pii-wrapper">
        {props.outputData ? (
          <>
            <div
              onClick={() => setShowPII(!showPII)}
              className="secret-wrapper"
            >
              <p className="secret-text">PII Data</p>
              <img src={showPII ? EyeOpen : EyeHidden} alt="eye" />
            </div>
            <div className="pii-output-wrapper">
              <div
                dangerouslySetInnerHTML={{
                  __html: replaceEntities(props.inputText),
                }}
              ></div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default PiiRedaction;
