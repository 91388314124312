import types from "../types/pipelineListingTypes";

const initialState = {
	categories: [],
	selectedCategory: {},
	service:{},
	prediction: {},
	labels: [],
	document: null,
	translate : {}
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case types.SET_NEB_ALL_CATEGORIES:
			return { ...state, categories: action.data };
		case types.SET_SELECTED_CATEGORY:
			return {...state, selectedCategory:action.data}
		case types.SET_SERVICE:
			return {...state, service: action.data}
		case types.SET_TEXT_RESULT:
			delete action.data.ResponseMetadata
			return {...state, prediction: action.data}
	    case types.SET_LABEL_DATA:
			return {...state, labels: action.data}
		case types.RESET_LABEL_DATA:
				return {...state, prediction:{},labels:[]}
		case types.SET_INPUT_DOCUMENT:
			return {...state, document: action.data}
		case types.SET_TRANSLATE_TEXT:
			return {...state, translate: action.data}
		default:
			return state;
	}
};

export default reducer;
