import React from "react";
import { langs } from "../../../constants/languages";
import PaiProgressBar from "../../pie-progress-bar/PaiProgressBar";
import "./DominantLanguage.scss";

function DominantLanguage({ outputData }) {
  return (
    <>
      <div className="dominant-language-wrapper">
        {outputData?.Languages?.map((language) => (
          <div>
            <span className="dominant-language-result">
              {langs[language.LanguageCode].name || "No Language Found"}
            </span>
          </div>
        ))}
      </div>
      {outputData?.Languages?.map((language) => (
        <div className="dominant-language-accuracy">
          <h2>Confidence</h2>
          <PaiProgressBar
            size={35}
            strokeWidth={3}
            percentage={language.Score * 100}
            color="#39AB5F"
          />
        </div>
      ))}
    </>
  );
}

export default DominantLanguage;
