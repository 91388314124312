import React, {useEffect, useState}from "react";
import { langs } from "../../constants/languages";
import arrowRight from "../../img/arrow-right.svg";
import arrowDown from "../../img/arrow-down.svg";
import types from "../../types/pipelineListingTypes";

import "./TextTranslationHeader.scss";
import { useDispatch } from "react-redux";

const TextTranslationHeader = ({
  langLeftValue,
  setLangLeftValue,
  langRightValue,
  setLangRightValue,
}) => {
  const [langLeft, setLangLeft] = useState(false);
  const [langRight, setLangRight] = useState(false);
  const dispatch = useDispatch()
  useEffect(() => {
    const data={
     langLeftValue,
     langRightValue
    }
    dispatch({
      type: types.SET_TRANSLATE_TEXT,
      data
    })
  }, [langLeftValue, langRightValue])
  // useEffect(() => {
   
  // }, [langRightValue])
  
  return (
    <div className="text-translation-header-wrapper">
      <div
        className="text-translation-header"
        onClick={() => setLangLeft(!langLeft)}
      >
        <span className="selected-lang">{langs[langLeftValue]?.name}</span>
        <img
          src={arrowDown}
          className={langLeft && "arrow-up"}
          alt="arrow-down"
        />
        {langLeft && (
          <ul className="language-dropdown">
            {Object.entries(langs).map((entry) => (
              <li
                type="link"
                key={entry[1]}
                onClick={() => setLangLeftValue(entry[0])}
              >
                {entry[1].name}
              </li>
            ))}
          </ul>
        )}
      </div>

      <img src={arrowRight} alt="arrowRight" />

      <div
        className="text-translation-header"
        onClick={() => setLangRight(!langRight)}
      >
        <span className="selected-lang">{langs[langRightValue]?.name}</span>
        <img
          src={arrowDown}
          className={langRight && "arrow-up"}
          alt="arrow-down"
        />
        {langRight && (
          <ul className="language-dropdown">
            {Object.entries(langs).map((entry) => (
              <li
                type="link"
                key={entry[1]}
                onClick={() => setLangRightValue(entry[0])}
              >
                {entry[1].name}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default TextTranslationHeader;
