import React from 'react'
import { getColor } from '../../../functions/nebulaHelper';
import "./TextClassification.scss"

function TextClassification(props) {
    const response = props?.outputData["response is"]
    return (
        <div className = "text-classification-wrapper">
            {
                response?.categories.map(text=> <div className={`${getColor(text.confidence)} confidence`}><span className="key">{text.name}</span></div>)
            }
        </div>
    )
}

export default TextClassification
