import React from "react";
import { useSelector } from "react-redux";
import {
  getColor,
  getConfidencePercentage,
} from "../../../functions/nebulaHelper";
import "./FaceMask.scss";

function FaceMask() {
  const image = useSelector((state) => state.nebulaCategories.document);
  const faceData =
    useSelector((state) => state.nebulaCategories.prediction?.Persons) || [];

  const getMappingValues = (item) => {
    return (item * 100).toFixed(2) + "%";
  };

  const faceMaskArray = () => {
    let faceMaskData = [];
    faceData?.forEach((person) => {
      person.BodyParts?.forEach((bodyPart) => {
        if (bodyPart.Name === "FACE") {
          bodyPart.EquipmentDetections.forEach((item) => {
            if (item.Type === "FACE_COVER") {
              faceMaskData.push(item);
            }
          });
        }
      });
    });
    return faceMaskData;
  };

  return (
    <div className="face-mask-wrapper">
      <div className="face-mask-container">
        <img
          src={image && URL.createObjectURL(image)}
          alt=""
          className="face-mask-output"
        />
        {faceMaskArray().map((face) => {
          const { Height, Width, Left, Top } = face.BoundingBox;
          const getClass = getColor(face.Confidence);
          return (
            <div
              className={`face-frame ${getClass}`}
              style={{
                height: getMappingValues(Height),
                width: getMappingValues(Width),
                left: getMappingValues(Left),
                top: getMappingValues(Top),
              }}
            >
              <span className={`frame-confidence ${getClass}`}>
                {getConfidencePercentage(face.Confidence / 100)}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default FaceMask;
