import React from 'react'
import { useSelector } from 'react-redux';
import { getColor } from '../../../functions/nebulaHelper';
import "./POS.scss"

function POS(props) {
    const selectedService = useSelector((state) => state.nebulaCategories.service);
  
    const tagEntities = (input) => {
    let outputString = input;
    let logs = [];
    if(selectedService.cloudServiceProvider==="aws"){
    props.outputData?.SyntaxTokens?.forEach((entity, i) => {
        const { PartOfSpeech, Text } = entity;
        let className = getColor(PartOfSpeech.Score);
        logs.push({
          tag: `--${i}--`,
          html: `<div class="entity-tag ${className}" name="tag"><span class="entity-text-span">${Text}</span>
          <span class="entity-type-span">${PartOfSpeech.Tag}</span></div>`,
        });
        outputString = outputString?.replace(
          Text,
          `--${i}--`
        );
      })
   
    }
    else{
        props.outputData["response is"].tokens?.forEach((entity, i) => {
          let className = "accurate";
          logs.push({
            tag: `--${i}--`,
            html: `<div class="entity-tag ${className}" name="tag"><span class="entity-text-span">${entity.text?.content}</span>
            <span class="entity-type-span">${entity.partOfSpeech?.tag}</span></div>`,
          });
          outputString = outputString?.replace(
           entity.text?.content,
            `--${i}--`
          );
        })
      }
      
    return { outputString: outputString, logs };
  };
  const replaceEntities = (input) => {
    let { outputString, logs } = tagEntities(input);
    logs.forEach((element) => {
      outputString = outputString?.replace(
        new RegExp(`${element.tag}`, "gi"),
        element.html
      );
    });
    return outputString;
  };
  return (
    <div
      dangerouslySetInnerHTML={{ __html: replaceEntities(props.inputText) }}
      className="pos-wrapper"
    ></div>
  );
}
export default POS
