import types from "../types/pipelineListingTypes";
import { NEBULA_URL, RequestMethod} from "../utils/config/nebula-api.config";

const setDataNB = (data) => ({
	type: types.SET_NEB_USER,
	data,
});

const nebulaUserData = (data) => ({
	type: types.SET_NEB_USER,
	data,
});

export const nebulaUserLogin = (params) => async (dispatch) => {
	  return new Promise(function (resolve, reject) {
		fetch(NEBULA_URL?.signIn, {
		method: RequestMethod.POST,
		body: params,
	  }).then(async (res) => {
        const result = await res.json()
				if (res.status === 200) {
				  resolve(result);
				  nebulaUserData(result)
				} else {
					reject(result)
					dispatch({
						type: types.POPUP_TRIGGER,
						data: {
							message: "Invalid username or password",
							type: "error",
						},
					});
				}
			  })
			  .catch((err) => {
				reject(err);
				dispatch({
					type: types.POPUP_TRIGGER,
					data: {
						message: "Invalid username or password",
						type: "error",
					},
				});
			  });
		  }
		  );
	
		
};

export const logoutUser = () => (dispatch) => {
	sessionStorage.clear();
	dispatch(setDataNB({}));
};
