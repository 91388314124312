import React from "react";
import { useSelector } from "react-redux";
import { getColor } from "../../../functions/nebulaHelper";
import "./Ner.scss";



function Ner(props) {
  const selectedService = useSelector((state) => state.nebulaCategories.service);
  const tagEntities = (input) => {
    let outputString = input;
    let logs = [];
   
    if(selectedService.cloudServiceProvider==="aws"){
      props.outputData?.Entities?.forEach((entity, i) => {
        const { Type, Text } = entity;
        let className = getColor(entity.Score);
        logs.push({
          tag: `--${i}--`,
          html: `<div class="entity-tag ${className}" name="tag"><span class="entity-text-span">${Text}</span>
          <span class="entity-type-span">${Type}</span></div>`,
        });
        outputString = outputString?.replace(
          Text,
          `--${i}--`
        );
      })
    }else{
      props.outputData["response is"].entities?.forEach((entity, i) => {
        let className = getColor(entity.salience);
        logs.push({
          tag: `--${i}--`,
          html: `<div class="entity-tag ${className}" name="tag"><span class="entity-text-span">${entity.name}</span>
          <span class="entity-type-span">${entity.type}</span></div>`,
        });
        outputString = outputString?.replace(
        entity.name,
          `--${i}--`
        );
      })
    }
    
    return { outputString: outputString, logs };
  };
  const replaceEntities = (input) => {
    let { outputString, logs } = tagEntities(input);
    logs.forEach((element) => {
      outputString = outputString?.replace(
        new RegExp(`${element.tag}`, "gi"),
        element.html
      );
    });
    return outputString;
  };
  return (
    <div
      dangerouslySetInnerHTML={{ __html: replaceEntities(props.inputText) }}
      className="ner-wrapper"
    ></div>
  );
}
export default Ner;
