import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import loginReducer from "./reducers/loginReducer";
import popupReducer from "./reducers/popupReducer";
import nebulaCategoriesReducer from "./reducers/nebulaCategoriesReducer";
import responsiveViewReducer from "./reducers/responsiveViewReducer";

const rootReducer = (history) =>
	combineReducers({
		router: connectRouter(history),
		loginUser: loginReducer,
		popup: popupReducer,
		nebulaCategories: nebulaCategoriesReducer,
		responsiveView: responsiveViewReducer,
	});

export default rootReducer;
