import React from "react";
import "./FaceDetection.scss";
import { useSelector } from "react-redux";
import {
	getColor,
	getConfidencePercentage,
} from "../../../functions/nebulaHelper";

function FaceDetection() {
	const image = useSelector((state) => state.nebulaCategories.document);
	const selectedService = useSelector(
		(state) => state.nebulaCategories.service
	);
	const isGCP = selectedService.cloudServiceProvider === "gcp";
	const responseGCP =
		useSelector(
			(state) =>
				state.nebulaCategories.prediction["response is"]?.faceAnnotations
		) || [];
	const responseAWS =
		useSelector((state) => state.nebulaCategories.prediction?.FaceDetails) ||
		[];
	let faceData = isGCP ? responseGCP : responseAWS;

	const getMappingValues = (item) => {
		if (isGCP) {
			const [pt1, pt2, pt3] = item.boundingPoly.vertices;
			const width = pt2.x - pt1.x + "px";
			const height = pt3.y - pt2.y + "px";
			const left = pt1.x + "px";
			const top = pt1.y + "px";

			return { width, height, left, top };
		} else {
			const { Height, Width, Left, Top } = item.BoundingBox;
			return {
				height: (Height * 100).toFixed(2) + "%",
				width: (Width * 100).toFixed(2) + "%",
				left: (Left * 100).toFixed(2) + "%",
				top: (Top * 100).toFixed(2) + "%",
			};
		}
	};

	return (
		<div className='face-detection-wrapper'>
			<div className='face-detection-container'>
				<img
					src={image && URL.createObjectURL(image)}
					alt=''
					className={isGCP ? "" : "face-detection-output"}
				/>
				{faceData?.map((face) => {
					const getClass = getColor(
						isGCP ? face.detectionConfidence : face.Confidence
					);
					return (
						<div
							className={`face-frame ${getClass}`}
							style={getMappingValues(face)}>
							<span className={`frame-confidence ${getClass}`}>
								{getConfidencePercentage(
									isGCP ? face.detectionConfidence : face.Confidence / 100
								)}
							</span>
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default FaceDetection;
