import _ from "lodash";
import types from "../types/pipelineListingTypes";
import {
	BASE,
	nebulaHeader,
	NEBULA_URL,
	RequestMethod
} from "../utils/config/nebula-api.config";

export const getAllServices = (params) => (dispatch) => {
	try {
		return fetch(NEBULA_URL.listAllActiveServices, {
			method: RequestMethod.GET,
			headers: nebulaHeader(),
		})
			.then((res) => res.json())
			.then((res) => {
				dispatch({
					type: types.SET_NEB_ALL_CATEGORIES,
					data: res,
				});
			});
	} catch (err) {
		console.log("ERROR", err);
	}
};

export const getResultsText = (params, url, use_case, service) => (dispatch) => {

	try {
		return fetch(`${BASE}${url}?` + new URLSearchParams(params), {
			method: service,
			headers: nebulaHeader(),
		})
			.then((res) => res.json())
			.then((res) => {
				dispatch({
					type: types.SET_TEXT_RESULT,
					data: res,
				});
				if (use_case.toLowerCase() === "natural_entity_recognition") {
					_.uniqBy(res.Entities, (e) => e.Type);
					dispatch({
						type: types.SET_LABEL_DATA,
						data: _.uniq(_.map(res.Entities, (e) => e.Type)),
					});
				}
			});
	} catch (err) {
		console.log("ERROR", err);
	}
};


export const postResultsText = (params, url, use_case) => (dispatch) => {
	try {
		return fetch(`${BASE}${url}`, {
			method: RequestMethod.POST,
			headers: nebulaHeader(),
			body: JSON.stringify(params),
		})
			.then((res) => res.json())
			.then((res) => {
				dispatch({
					type: types.SET_TEXT_RESULT,
					data: res,
				});
			});
	} catch (err) {
		console.log("ERROR", err);
	}
};
