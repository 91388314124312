import React from "react";
import { useSelector } from "react-redux";
import "./ExplicitContentDetection.scss";

function ExplicitContentDetection() {
  const image = useSelector((state) => state.nebulaCategories.document);
  const selectedService = useSelector(
    (state) => state.nebulaCategories.service
  );
  const isGCP = selectedService.cloudServiceProvider === "gcp";
  const responseAWS =
    useSelector(
      (state) => state.nebulaCategories.prediction?.ModerationLabels
    ) || [];
  const responseGCP =
    useSelector((state) => state.nebulaCategories.prediction["response is"]) ||
    [];

  let labelData = isGCP ? responseGCP : responseAWS;

  const getAWSSafe = (data) => {
    let allLabels = [];
    if (data.length) {
      data.map((obj) => obj.ParentName && allLabels.push(obj.Name));
    } else {
      return (
        <div className="safe-text">
          <span className=" pre-text safe-text">Safe To Use</span>
        </div>
      );
    }
    return allLabels.length ? (
      allLabels.map((label) => (
        <div className="not-safe-text">
          <span className="pre-text">{label}</span>{" "}
        </div>
      ))
    ) : (
      <div className="safe-text">
        <span className=" pre-text ">Safe To Use</span>
      </div>
    );
  };

  const getGCPSafe = (data) => {
    let allLabels = [];
    for (const [key, value] of Object.entries(data?.safeSearchAnnotation)) {
      if (value === "LIKELY" || value === "VERY_LIKELY") {
        allLabels.push(key);
      }
    }
    return allLabels.length ? (
      allLabels.map((label) => (
        <div className="not-safe-text">
          <span className=" pre-text ">{label}</span>
        </div>
      ))
    ) : (
      <div className="safe-text">
        <span className=" pre-text">Safe To Use</span>
      </div>
    );
  };

  return (
    <div className="explicit-content-wrapper">
      <div className="image-container">
        <img src={image && URL.createObjectURL(image)} alt="uploadedImage" />
      </div>

      <div className="text-container">
        {isGCP ? getGCPSafe(labelData) : getAWSSafe(labelData)}
      </div>
    </div>
  );
}

export default ExplicitContentDetection;
